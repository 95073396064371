/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type CompanyInfoView = {
    companyName: string;
    transactionStartDate: string;
    transactionEndDate: string;
    isMaterialAutoAssigned: boolean;
    firstDayOfWorkpackWeek: string;
    dashboardUrl: string;
    isBilingual: boolean;
    allowNextDueCalcToBeToday: boolean;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type ConnectStatusView = {
    normalUrl: string;
    alertUrl: string;
    isAlert: boolean;
};
export type YearWeekValue = {
    yearWeek: string;
    value: number;
};
export type HomePageView = {
    completionPercentageList: YearWeekValue[];
    inspectionNonComplianceEvents: number;
    openWorkpacks: number;
    openWorksheets: number;
    openCars: number;
    laborHours: number;
    forecastHours: number;
};
export function getCompanyInformation(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: CompanyInfoView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/companyinfo", {
        ...opts
    });
}
export function getCompanyConnectStatus(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ConnectStatusView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/companyinfo/connectstatus", {
        ...opts
    });
}
export function getCompanyHomePage(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: HomePageView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/companyinfo/home", {
        ...opts
    });
}
