/**
 * PlantApi
 * 1.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
    headers: {},
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type DditDto = {
    "number": number;
    name: string;
    active: boolean;
    forecastMinutes: number;
    equip: number;
    pdfName: string;
    skill: string;
};
export type DataStatus = "success" | "notValid" | "exception" | "error" | "inUse" | "notFound" | "noChange" | "inProgress" | "invalidPage" | "duplicateEntity" | "noContent";
export type ErrorResponse = {
    message: string;
    kind: DataStatus;
    exception: string | null;
    stackTrace: string | null;
    exceptionType: string;
    innerException: string | null;
    validationErrors: {
        [key: string]: string[];
    } | null;
};
export type ValidationProblemDetails = {
    "type": string | null;
    title: string | null;
    status?: number | null;
    detail: string | null;
    instance: string | null;
    errors: {
        [key: string]: string[];
    };
    [key: string]: any;
};
export type PdfNameDto = {
    "number": number;
    success: boolean;
};
export type GpitDto = {
    id: string;
    description: string;
    descriptionBilingual: string;
    showCompleted: boolean;
    showForEquipment: boolean;
    showForNonEquipment: boolean;
};
export type GpitView = {
    globalBeforeTasks: GpitDto[];
    globalAfterTasks: GpitDto[];
};
export type LibraryHeaderViewDto = {
    "number": number;
    name: string;
};
export type TaskListMaterialDto = {
    code: string;
    qty: number;
};
export type LibrDto = {
    item: number;
    item2: string;
    description: string;
    descriptionSecondLanguage: string;
    weeks?: number | null;
    showConfirmation: boolean;
    startOffsetWeeks: number;
    calculateMethod: string;
    canPerformWhileRunning: boolean;
    skill: string;
    forecast: number;
    priority: number;
    hasControlPoint: boolean;
    controlPointType: string;
    cpIsRangeChecked: boolean;
    cpRangeStart: number;
    cpRangeEnd: number;
    cpIsStartTime: boolean;
    cpIsEndTime: boolean;
    cpSwabType: number;
    materialList: TaskListMaterialDto[];
    imagesList: number[];
};
export type LibraryDto = {
    name: string;
    libraryTaskList: LibrDto[];
};
export type TaskListMaterialView = {
    code: string;
    location: string;
    qty: number;
    barcode: number;
    name: string;
    primaryVendor: number;
    vendorPartNumber: string;
    mfgPartNumber: string;
};
export type LibraryDetailViewDto = {
    item: number;
    item2: string;
    description: string;
    descriptionSecondLanguage: string;
    weeks: number;
    days: number;
    hours: number;
    showConfirmation: boolean;
    startOffsetWeeks: number;
    lastScheduledDate?: string | null;
    nextDueDate?: string | null;
    lastCompletedDate?: string | null;
    startHours: number;
    hoursOffset: number;
    lastScheduledHours: number;
    nextDueHours: number;
    lastCompletedHours: number;
    calculateMethod: string;
    canPerformWhileRunning: boolean;
    skill: string;
    forecast: number;
    priority: number;
    hasControlPoint: boolean;
    controlPointType: string;
    cpIsRangeChecked: boolean;
    cpRangeStart?: number | null;
    cpRangeEnd?: number | null;
    cpIsStartTime: boolean;
    cpIsEndTime: boolean;
    cpSwabType: number;
    materialList: TaskListMaterialView[];
    imagesList: number[];
};
export type LibraryViewDto = {
    libraryHeader: LibraryHeaderViewDto;
    libraryTaskList: LibraryDetailViewDto[];
};
export type LibraryTaskListDto = {
    libraryTaskList: LibrDto[];
};
export type PmitViewDto = {
    item: number;
    item2: string;
    originalItem: number;
    originalItem2: string;
    description: string;
    descriptionSecondLanguage: string;
    weeks: number;
    days: number;
    hours: number;
    showConfirmation: boolean;
    startOffsetDays: number;
    startOffsetWeeks: number;
    lastScheduledDate?: string | null;
    nextDueDate?: string | null;
    lastCompletedDate?: string | null;
    startHours: number;
    hoursOffset: number;
    lastScheduledHours: number;
    nextDueHours: number;
    lastCompletedHours: number;
    calculateMethod: string;
    canPerformWhileRunning: boolean;
    skill: string;
    forecast: number;
    priority: number;
    hasControlPoint: boolean;
    controlPointType: string;
    cpIsRangeChecked: boolean;
    cpRangeStart: number;
    cpRangeEnd: number;
    cpIsStartTime: boolean;
    cpIsEndTime: boolean;
    cpSwabType: number;
    materialList: TaskListMaterialView[];
    imagesList: number[];
};
export type TaskDocumentViewDto = {
    "number": number;
    name: string;
    startTaskCalculationDate: string;
    nextWeekDate?: string | null;
    active: boolean;
    lineNumber: number;
    workGroupNumber: number;
    corporateCategoryNumber: number;
    mobileGenerateSheet: boolean;
    noTaskForward: boolean;
    corporateLock: boolean;
    pmitList: PmitViewDto[];
    beforeTasksImagesList: number[];
    afterTasksImagesList: number[];
};
export type PmitDto = {
    item: number;
    item2: string;
    originalItem: number;
    originalItem2: string;
    description: string;
    descriptionSecondLanguage: string;
    weeks: number;
    hours: number;
    showConfirmation: boolean;
    startOffsetWeeks: number;
    nextDueDate?: string | null;
    startHours: number;
    hoursOffset: number;
    nextDueHours: number;
    calculateMethod: string;
    canPerformWhileRunning: boolean;
    skill: string;
    forecast: number;
    priority: number;
    hasControlPoint: boolean;
    controlPointType: string;
    cpIsRangeChecked: boolean;
    cpRangeStart?: number | null;
    cpRangeEnd?: number | null;
    cpIsStartTime: boolean;
    cpIsEndTime: boolean;
    cpSwabType: number;
    materialList: TaskListMaterialDto[];
    imagesList: number[];
};
export type TaskDocumentDto = {
    reason: string;
    pmitList: PmitDto[];
    beforeTasksImagesList: number[];
    afterTasksImagesList: number[];
};
export type TaskDocumentLockStatusDto = {
    "number": number;
    isLocked: boolean;
    lockDate?: string | null;
    lockUser: string;
    lockUserId: number;
};
export type ImageListDto = {
    "number": number;
    description: string;
    equipNumber: number;
    materialCode: string;
    typeDescription: string;
    item: number;
    item2: string;
    vertical: number;
    horizontal: number;
};
export function getDditList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DditDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/ddit", {
        ...opts
    });
}
export function createDdit(dditDto: DditDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DditDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/ddit", oazapfts.json({
        ...opts,
        method: "POST",
        body: dditDto
    }));
}
export function getDdit($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DditDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/ddit/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateDdit($number: number, dditDto: DditDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DditDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 422;
        data: ValidationProblemDetails;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/ddit/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: dditDto
    }));
}
export function deleteDdit($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/ddit/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get PDF in Stream
 */
export function getDditReport($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/ddit/${encodeURIComponent($number)}/pdf`, {
        ...opts
    });
}
/**
 * Upload PDF to the Daily Duty, New or Replacement PDF. Only one per Daily Duty.   multipart/form-data
 */
export function updateDditReport($number: number, body?: {
    files: Blob[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PdfNameDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/ddit/${encodeURIComponent($number)}/setpdf`, oazapfts.multipart({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Two arrays are provided - Before Worksheets and After Worksheet Tasks. NOTE: 255 Char Length Max
 */
export function getGpitList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GpitView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/gpit", {
        ...opts
    });
}
/**
 * Send back both arrays - Global Task for Before and After Worksheet Tasks
 */
export function createGpit(gpitView: GpitView, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GpitView;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/gpit", oazapfts.json({
        ...opts,
        method: "POST",
        body: gpitView
    }));
}
export function getLibraryList(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LibraryHeaderViewDto[];
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/librarymanagement", {
        ...opts
    });
}
export function createLibrary(libraryDto: LibraryDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LibraryViewDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/librarymanagement", oazapfts.json({
        ...opts,
        method: "POST",
        body: libraryDto
    }));
}
export function getLibrary($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LibraryViewDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/librarymanagement/${encodeURIComponent($number)}`, {
        ...opts
    });
}
export function updateLibrary($number: number, libraryTaskListDto: LibraryTaskListDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LibraryViewDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/librarymanagement/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: libraryTaskListDto
    }));
}
export function deleteLibrary($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/librarymanagement/${encodeURIComponent($number)}`, {
        ...opts,
        method: "DELETE"
    });
}
export function getTaskList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentViewDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}`, {
        ...opts
    });
}
/**
 * Update/Create Task Document
 */
export function updateTaskList($number: number, taskDocumentDto: TaskDocumentDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: taskDocumentDto
    }));
}
/**
 * Check if Task List is Locked
 */
export function taskListCheckLockStatus($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentLockStatusDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}/checkiflocked`, {
        ...opts
    });
}
/**
 * Set Task List as Locked (Edit Mode)
 */
export function taskListSetLocked($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentLockStatusDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 403;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}/lock`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Clear the Task List Lock ONLY when Canceling Edit Mode
 */
export function taskListClearLockedStatus($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentLockStatusDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 403;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}/unlock`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Calculate Task Document
 */
export function calculateTaskList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TaskDocumentDto;
    } | {
        status: 204;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/tasklistmanagement/${encodeURIComponent($number)}/calculate`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Returns just the image, no other data.
 */
export function getImageList($number: number, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: string;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 500;
        data: ErrorResponse;
    }>(`/api/imagelibrary/${encodeURIComponent($number)}`, {
        ...opts
    });
}
/**
 * Uploaded images are resized to 800px max dimension as JPEG.
 */
export function createImageList(body?: {
    files: Blob[];
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImageListDto;
    } | {
        status: 400;
        data: ErrorResponse;
    } | {
        status: 401;
    } | {
        status: 404;
        data: ErrorResponse;
    } | {
        status: 409;
    } | {
        status: 500;
        data: ErrorResponse;
    }>("/api/imagelibrary", oazapfts.multipart({
        ...opts,
        method: "POST",
        body
    }));
}
